import React from "react";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazy-load";
import indieIcon from "../visual/badge-indie-OberionIO.min.svg";
import IconSupplier from "../../element/graphical/IconSupplier";
import TourHeaderSupplier from "./TourHeaderSupplier";
import RatingTooltip from "./RatingTooltip";
import StoreButton from "./StoreButton";
import Award from "./Award";
import MediaCarousel from "./Carousel/MediaCarousel-ImageGallery";
// import MediaCarousel from "./Carousel/MediaCarousel";

const useStyles = makeStyles(theme => ({
  card: {
    display: "flex",
    flexFlow: "row wrap",
    backgroundColor: "rgba(0,0,44,0.6)",
  },
  contentNoBottomMargin: {
    margin: "0.5rem 0.5rem 0 0.5rem",
    padding: "0.5rem 0.5rem 0 0.5rem !important",
  },
  content: {
    // display: "flex",
    // flexFlow: "column nowrap",
    // justifyContent: "flex-end",
    // alignItems: "center",
  },
  contentNoTopMargin: {
    display: "block",
    width: "100%",
    margin: "0 0.5rem 0.5rem 0.5rem",
    padding: "0 0.5rem 0.5rem 0.5rem !important",
  },
  button: {
    border: "solid 2px !important",
    padding: "0.3rem 0.5rem",
    textTransform: "none",
    margin: "0 auto",
  },
  rightIcon: {
    height: "1.5rem",
    marginLeft: theme.spacing(1),
  },
  chip: {
    margin: "0.2rem 0.5rem 0.1rem 0",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  growDivider: {
    display: "block",
    width: "100%",
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
  },
}));

const GamePreviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;

  align-items: center;
  margin: 1.5rem 0;
`;
const RowDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  min-width: 100%;
`;
const HalfedCard = styled(Grid)`
  flex-basis: 50%;
  flex-grow: 1;
`;
const AwardsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CarouselDiv = styled.div`
  display: flex;
  justify-content: center;
`;


const text = [
  "Why you might like this",
  "Available on",
];

/**
 * @see https://medium.com/@rossbulat/lazy-image-loading-in-react-the-full-breakdown-4026619de2df
 */
const GamePreview = ({ game }) => {
  const classes = useStyles();

  return (
    <GamePreviewContainer>
      <Card className={classes.card}>

        <Grid container spacing={3}>
          <HalfedCard item sx={12} md={6}>

            <CardContent className={classes.contentNoBottomMargin}>
              <TourHeaderSupplier gameHeader={game.gameHeader} />
              <Typography variant="h6" gutterBottom>{game.gameName}</Typography>
              <Typography variant="caption" paragraph>{`by ${game.developer}`}</Typography>
              <RatingTooltip userRating={(game.score / 20)} score={game.score}
                             ratings={game.ratings} />
            </CardContent>

            <CardContent className={classes.contentNoBottomMargin}>
              <Typography variant="caption">{game.shortDescription}</Typography>
              <Divider className={classes.divider} component="hr" light />


            </CardContent>
          </HalfedCard>

          <HalfedCard item sx={12} md={6}>
            <CardContent className={classes.content}>

              <AwardsDiv>
                {game.awards.map((element, index) => {
                  return (
                    <Award type={element.type}
                           caption={element.caption}
                           key={index} />
                  );
                })}
              </AwardsDiv>

              <Divider className={classes.divider} component="hr" light />

              <CarouselDiv>
                <LazyLoad offsetVertical={600}>
                  <MediaCarousel media={game.media} />
                </LazyLoad>
              </CarouselDiv>


            </CardContent>
          </HalfedCard>
        </Grid>

        <CardContent className={classes.contentNoTopMargin}>
          <Typography variant="h6" gutterBottom>{text[0]}</Typography>
          {game.gameChip.map((element) => {
            return (
              <Chip label={element}
                    className={classes.chip}
                    color="secondary"
                    size="small"
                    icon={<IconSupplier caption="Indie" icon={indieIcon} />}
                    component="div" key={element.slice(0, 10)} />
            );
          })}
          {game.reasonsToPlay.map((element, index) => {
            return (
              <Chip label={element}
                    className={classes.chip}
                    variant="outlined"
                    component="div"
                    key={`${element.slice(0, 3)}-${index}`} />
            );
          })}
          <Divider className={classes.growDivider} component="hr" light />
          <RowDiv>
            <Typography variant="caption" component="span">{text[1]}</Typography>
            <div>
              {Object.keys(game.storeLinks).map(store => (
                <StoreButton store={store} storeHref={game.storeLinks[store]} key={store} />
              ))}
            </div>
          </RowDiv>
        </CardContent>

      </Card>
    </GamePreviewContainer>
  );
};
GamePreview.defaultProps = {};
GamePreview.propTypes = {
  game: PropTypes.shape({
    gameId: PropTypes.number.isRequired,
    gameHeader: PropTypes.string.isRequired,
    gameName: PropTypes.string.isRequired,
    developer: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    ratings: PropTypes.number.isRequired,
    shortDescription: PropTypes.string.isRequired,
    gameChip: PropTypes.arrayOf(PropTypes.string),
    reasonsToPlay: PropTypes.arrayOf(PropTypes.string),
    storeLinks: PropTypes.objectOf(PropTypes.string),
    awards: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    media: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};

export default GamePreview;
export const data = {
  games: [
    {
      gameId: 0,
      steamid: 504230,
      gameName: "Celeste",
      score: 97,
      ratings: 9306,
      gameHeader: "celeste.jpg",
      developer: "Matt Making Games",
      shortDescription: "Help Madeline survive her inner demons on her journey to the top of Celeste Mountain, in this super-tight platformer from the creators of TowerFall. Brave hundreds of hand-crafted challenges, uncover devious secrets, and piece together the mystery of the mountain.",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/504230/Celeste/",
        epic: "https://www.epicgames.com/store/en-US/product/celeste/home",
        humble: "https://www.humblebundle.com/store/celeste",
        nintendo: "https://www.nintendo.com/games/detail/celeste-switch/",
        playstation: "https://store.playstation.com/en-us/product/UP2120-CUSA11302_00-CELESTEXXCELESTE",
      },
      reasonsToPlay: ["Smooth gameplay", "Platformer", "Easy to learn, hard to master", "Beautiful pixel graphics", "Charming story", "Incredible soundtrack", "Madeline needs your help", "Climb the mountain"],
      awards: [
        {
          type: "star",
          caption: "#1 Indie",
        },
        {
          type: "ivy",
          caption: "Favorite on Reddit",
        },
      ],
      media: [
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_1ad297c2044cdcf450ee83e56350cafb590da755.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_1ad297c2044cdcf450ee83e56350cafb590da755.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_03bfe6bd5ddac7f747c8d2aa1a4f82cfd53c6dcb.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_03bfe6bd5ddac7f747c8d2aa1a4f82cfd53c6dcb.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_4b0f0222341b64a37114033aca9994551f27c161.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_4b0f0222341b64a37114033aca9994551f27c161.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_1012b11ad364ad6c138a25a654108de28de56c5f.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_1012b11ad364ad6c138a25a654108de28de56c5f.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_832ef0f27c3d6efdaa4b5d1cc896dce0999bc9e8.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_832ef0f27c3d6efdaa4b5d1cc896dce0999bc9e8.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_1098b655a622720cfd549b104736a4eca8948100.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_1098b655a622720cfd549b104736a4eca8948100.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_0eab901ec5c364aa18225fa608ff9cbcc1f432bf.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_0eab901ec5c364aa18225fa608ff9cbcc1f432bf.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_3140f6f87aa74c20e142c36d74691f930eda88d5.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_3140f6f87aa74c20e142c36d74691f930eda88d5.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_e0159d08620e917e632788c960d9593e5e7f431c.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_e0159d08620e917e632788c960d9593e5e7f431c.1920x1080.jpg?t=1567740791",
        },
        {
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_152d7e5459c7d6d676ab837abe4355907757ae2d.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_152d7e5459c7d6d676ab837abe4355907757ae2d.1920x1080.jpg?t=1567740791",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_726b2d2cd47cfd785c1aed73d65509037c822495.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_726b2d2cd47cfd785c1aed73d65509037c822495.1920x1080.jpg?t=1567740791",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_a110fe2f50c5828af4b1ff4e7c1ca773a1a7e5aa.600x338.jpg?t=1567740791",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/504230/ss_a110fe2f50c5828af4b1ff4e7c1ca773a1a7e5aa.1920x1080.jpg?t=1567740791",
        },
      ],
    },
    {
      gameId: 12,
      steamid: 583470,
      gameName: "The End Is Nigh",
      score: 92,
      ratings: 1553,
      gameHeader: "the-end-is-nigh.jpg",
      developer: "The End Is Nigh is a sprawling adventure platformer where you die a lot, but thats ok because you are probably already dead anyway.",
      shortDescription: "by Edmund McMillen and Tyler Glaiel",
      gameChip: ["Super Meat Boy / Binding of Isaac Devs"],
      storeLinks: {
        epic: "https://www.epicgames.com/store/en-US/product/the-end-is-nigh/home",
        steam: "https://store.steampowered.com/app/583470/The_End_Is_Nigh/",
      },
      reasonsToPlay: ["Challenging platformer", "Dark atmosphere", "Precision needed", "Rich Evans character", "Puzzle", "Great soundtrack"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_949c94ad29bfdc59411e497364228cfbfc4415ee.600x338.jpg?t=1513035069",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_949c94ad29bfdc59411e497364228cfbfc4415ee.1920x1080.jpg?t=1513035069",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_a24ea4b3e1b2835ef3d344ddf9d39bc6a82b52df.600x338.jpg?t=1513035069",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_a24ea4b3e1b2835ef3d344ddf9d39bc6a82b52df.1920x1080.jpg?t=1513035069",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_3783211f9f493aac1f20c0844aa3df81a224d30a.600x338.jpg?t=1513035069",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_3783211f9f493aac1f20c0844aa3df81a224d30a.1920x1080.jpg?t=1513035069",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_762a51765372efbd33f896b0fc4df50819f15312.600x338.jpg?t=1513035069",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_762a51765372efbd33f896b0fc4df50819f15312.1920x1080.jpg?t=1513035069",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_bf0dc241c831d952638a2238eff099ba28fd0c24.600x338.jpg?t=1513035069",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_bf0dc241c831d952638a2238eff099ba28fd0c24.1920x1080.jpg?t=1513035069",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_56c71c108ebe2ec3a911031bb3083438101f5a3e.600x338.jpg?t=1513035069",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_56c71c108ebe2ec3a911031bb3083438101f5a3e.1920x1080.jpg?t=1513035069",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_451c935fa1f5965c055cce990947235d576ef3ec.600x338.jpg?t=1513035069",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/583470/ss_451c935fa1f5965c055cce990947235d576ef3ec.1920x1080.jpg?t=1513035069",
        },
      ],
    },
    {
      gameId: 1,
      steamid: 251470,
      gameName: "TowerFall Ascension",
      score: 95,
      ratings: 1676,
      gameHeader: "towerfall-ascension.jpg",
      developer: "Matt Thorson",
      shortDescription: "TowerFall Ascension is the definitive version of the hit archery combat game. Inspired by classics from the golden age of couch multiplayer, it's a 4-player local party game centering around hilarious, intense versus matches. The core mechanics are simple and accessible, but hard to master and combat is fierce. ",
      gameChip: ["From the creator of Celeste"],
      storeLinks: {
        nintendo: "https://www.nintendo.com/games/detail/towerfall-switch/",
        steam: "https://store.steampowered.com/app/251470/TowerFall_Ascension/",
      },
      reasonsToPlay: ["2D Combat", "Local multiplayer", "Couch co-op", "Beautiful pixel graphics"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_5f5782e7f91f99dbeb7f9a561771378f850ae872.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_5f5782e7f91f99dbeb7f9a561771378f850ae872.1920x1080.jpg?t=1455735772",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_aa0f804052ab6b93994c5893837445c789bf35ea.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_aa0f804052ab6b93994c5893837445c789bf35ea.1920x1080.jpg?t=1455735772",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_01a805ebff71a1b5fb392f7c09ee0b8f73707ed7.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_01a805ebff71a1b5fb392f7c09ee0b8f73707ed7.1920x1080.jpg?t=1455735772",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_eebc6b6dbf2ceec96946b4e67e3ddd31fd8a50b0.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_eebc6b6dbf2ceec96946b4e67e3ddd31fd8a50b0.1920x1080.jpg?t=1455735772",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_e01d3cb9357cf583f4e9be1d7d22c48d2e24f600.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_e01d3cb9357cf583f4e9be1d7d22c48d2e24f600.1920x1080.jpg?t=1455735772",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_8a230efea8416904a3d7e6a3aa145e9fa40a356b.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_8a230efea8416904a3d7e6a3aa145e9fa40a356b.1920x1080.jpg?t=1455735772",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_15d95c4c3c19032a44985faabe531282698196c8.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_15d95c4c3c19032a44985faabe531282698196c8.1920x1080.jpg?t=1455735772",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_28af47e432b9107da2c32e758103d5bced88e243.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_28af47e432b9107da2c32e758103d5bced88e243.1920x1080.jpg?t=1455735772",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_1227c7a3d51dfacc9d97f33b980f77f40066ce4d.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_1227c7a3d51dfacc9d97f33b980f77f40066ce4d.1920x1080.jpg?t=1455735772",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_430eb8f0d8284c7af5d03d37d6f5a416a0179831.600x338.jpg?t=1455735772",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/251470/ss_430eb8f0d8284c7af5d03d37d6f5a416a0179831.1920x1080.jpg?t=1455735772",
        },
      ],
    },
    {
      gameId: 2,
      steamid: 253230,
      gameName: "A Hat in Time",
      score: 98,
      ratings: 15965,
      gameHeader: "a-hat-in-time.jpg",
      developer: "Gears For Breakfast",
      shortDescription: "A Hat in Time is a cute-as-heck 3D platformer featuring a little girl who stitches hats for wicked powers! Freely explore giant worlds and recover Time Pieces to travel to new heights!",
      gameChip: ["Coming to Nintendo Switch soon"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/253230/A_Hat_in_Time/",
      },
      reasonsToPlay: ["Similar to Banjoo-Kazooie", "3D Adventure", "Singleplayer", "Sell your soul", "Repair your space ship"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_eb442a7301b8a0d8e94c4064575ebe68d77deb12.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_eb442a7301b8a0d8e94c4064575ebe68d77deb12.1920x1080.jpg?t=1561572780",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_b9a71f27ac4c7428666490065b872087c9d8cacb.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_b9a71f27ac4c7428666490065b872087c9d8cacb.1920x1080.jpg?t=1561572780",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_b02afa3ea6adafefdcead6db5b022350cb07ac56.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_b02afa3ea6adafefdcead6db5b022350cb07ac56.1920x1080.jpg?t=1561572780",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_8484e41ad2dd4507b3025b52e9ec46a3d1ff9c73.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_8484e41ad2dd4507b3025b52e9ec46a3d1ff9c73.1920x1080.jpg?t=1561572780",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_40164f47cf9968dca15a1e70547bf286b32b93c1.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_40164f47cf9968dca15a1e70547bf286b32b93c1.1920x1080.jpg?t=1561572780",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_b53ce2961bf920347518950a2726b93f48a10606.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_b53ce2961bf920347518950a2726b93f48a10606.1920x1080.jpg?t=1561572780",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_a70784fe0519c1465d185872ec66efe92fdb6c15.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_a70784fe0519c1465d185872ec66efe92fdb6c15.1920x1080.jpg?t=1561572780",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_5e6bc903ae4875e8faaa9f7ea21d68383a73bbc9.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_5e6bc903ae4875e8faaa9f7ea21d68383a73bbc9.1920x1080.jpg?t=1561572780",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_1b984b79fb64d3b91a79c185d760c90f9f7ff60a.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_1b984b79fb64d3b91a79c185d760c90f9f7ff60a.1920x1080.jpg?t=1561572780",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_5ffcb224a75988f726106866eb790ef79e453fe6.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_5ffcb224a75988f726106866eb790ef79e453fe6.1920x1080.jpg?t=1561572780",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_24648d05f16fe1cdb50965d8ccc8d029dbb70512.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_24648d05f16fe1cdb50965d8ccc8d029dbb70512.1920x1080.jpg?t=1561572780",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_c37d9302c20cf1e2a0f286eb7444483a76689b1c.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_c37d9302c20cf1e2a0f286eb7444483a76689b1c.1920x1080.jpg?t=1561572780",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_e873385538d4355506977b13c145dafdcc44fdd6.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_e873385538d4355506977b13c145dafdcc44fdd6.1920x1080.jpg?t=1561572780",
        },
        {
          id: 13,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_9ecb1b8dae3de8cdd518350462a51bad1f277caf.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_9ecb1b8dae3de8cdd518350462a51bad1f277caf.1920x1080.jpg?t=1561572780",
        },
        {
          id: 14,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_52ff637b35d676a88f5853542b34644a526ce248.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_52ff637b35d676a88f5853542b34644a526ce248.1920x1080.jpg?t=1561572780",
        },
        {
          id: 15,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_4dfcb4aaca9a064893cd3bd988e6e13d9a04ec49.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_4dfcb4aaca9a064893cd3bd988e6e13d9a04ec49.1920x1080.jpg?t=1561572780",
        },
        {
          id: 16,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_88ea21213a4c788a1736dd45a70ae0f85ae4746d.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_88ea21213a4c788a1736dd45a70ae0f85ae4746d.1920x1080.jpg?t=1561572780",
        },
        {
          id: 17,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_6c4cd183a85ba42f93eb4d39743ed0e43c164351.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_6c4cd183a85ba42f93eb4d39743ed0e43c164351.1920x1080.jpg?t=1561572780",
        },
        {
          id: 18,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_9b0146cf588d665e29bb9250c44abd9aec658103.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_9b0146cf588d665e29bb9250c44abd9aec658103.1920x1080.jpg?t=1561572780",
        },
        {
          id: 19,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_6453e79583a08067dca7ed933628b3f6ff102bf6.600x338.jpg?t=1561572780",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/253230/ss_6453e79583a08067dca7ed933628b3f6ff102bf6.1920x1080.jpg?t=1561572780",
        },
      ],
    },
    {
      gameId: 3,
      steamid: 334940,
      gameName: "Yoku's Island Express",
      score: 96,
      ratings: 530,
      gameHeader: "yokus-island-express.jpg",
      developer: "Villa Gorilla & Team17",
      shortDescription: "Meet Yoku, the pint-sized postman protagonist of Yoku's Island Express! Use a unique blend of pinball mechanics, platforming and open world exploration to unlock the secrets of Mokumana Island, help the locals, awaken ancient deities and much more in this amazing new tropical adventure!",
      gameChip: ["Try the DEMO"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/334940/Yokus_Island_Express/",
        nintendo: "https://www.nintendo.com/games/detail/yokus-island-express-switch/",
      },
      reasonsToPlay: ["Metroidvania-pinball hybrid", "Open-world pinball adventure", "Explore", "Deliver important post", "Innovative game design"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_a23eaefb9d299e5f10e7eb721fe4abc7e8ab9bbb.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_a23eaefb9d299e5f10e7eb721fe4abc7e8ab9bbb.1920x1080.jpg?t=1568823733",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_f386401fb8151eb9b48caa2182f29b886a4a3cf2.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_f386401fb8151eb9b48caa2182f29b886a4a3cf2.1920x1080.jpg?t=1568823733",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_79752273e90e872597f31ba9bb9bd0cf0c7f23de.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_79752273e90e872597f31ba9bb9bd0cf0c7f23de.1920x1080.jpg?t=1568823733",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_0131a0791cac53b6274a3b4111d77b78ce0307d7.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_0131a0791cac53b6274a3b4111d77b78ce0307d7.1920x1080.jpg?t=1568823733",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_41ed918bfba0017f4cb488769b58a5b5287dc92e.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_41ed918bfba0017f4cb488769b58a5b5287dc92e.1920x1080.jpg?t=1568823733",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_120e06751c452dae9d6282e8e7c09841b3863e83.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_120e06751c452dae9d6282e8e7c09841b3863e83.1920x1080.jpg?t=1568823733",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_e3b84c526a6a00ad6af354a2d522779bef2a6db9.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_e3b84c526a6a00ad6af354a2d522779bef2a6db9.1920x1080.jpg?t=1568823733",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_3e5a9dc2fc1fa57fcde762d09662b0576ce445e5.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_3e5a9dc2fc1fa57fcde762d09662b0576ce445e5.1920x1080.jpg?t=1568823733",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_d9d59d6f6b242c91efa3fd7162433f6b93e3d67b.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_d9d59d6f6b242c91efa3fd7162433f6b93e3d67b.1920x1080.jpg?t=1568823733",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_581d71e750f96f4b7a394286961f7da1a98077ec.600x338.jpg?t=1568823733",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/334940/ss_581d71e750f96f4b7a394286961f7da1a98077ec.1920x1080.jpg?t=1568823733",
        },
      ],
    },
    {
      gameId: 4,
      steamid: 588650,
      gameName: "Dead Cells",
      score: 95,
      ratings: 27397,
      gameHeader: "dead-cells.jpg",
      developer: "Motion Twin",
      shortDescription: "Dead Cells is a rogue-lite, metroidvania inspired, action-platformer. You'll explore a sprawling, ever-changing castle... assuming you’re able to fight your way past its keepers in 2D souls-lite combat. No checkpoints. Kill, die, learn, repeat.",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/588650/Dead_Cells/",
        nintendo: "https://www.nintendo.com/games/detail/dead-cells-switch/",
      },
      reasonsToPlay: ["Dark Souls in 2D", "Similar to Hollow Knight", "Action-Adventure", "Requires pure dedication", "Roguelite? Metroidvania? Roguevania!", "Explore", "Raised on the salt of the testers"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_21c61aca6a66745a2abb3f72b93553398fc7fe32.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_21c61aca6a66745a2abb3f72b93553398fc7fe32.1920x1080.jpg?t=1566402278",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_b7cf2ca21fe3648c53f808b80393cc727815dcc5.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_b7cf2ca21fe3648c53f808b80393cc727815dcc5.1920x1080.jpg?t=1566402278",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_a109be71f065e166ff33ba43853fba0a0d4753d1.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_a109be71f065e166ff33ba43853fba0a0d4753d1.1920x1080.jpg?t=1566402278",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_a8b0439ad7750cab1bdec86ecef0daa280e9f93f.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_a8b0439ad7750cab1bdec86ecef0daa280e9f93f.1920x1080.jpg?t=1566402278",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_7bde51ea6c8f6289e85ea1d8c1c941e1f8bfee91.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_7bde51ea6c8f6289e85ea1d8c1c941e1f8bfee91.1920x1080.jpg?t=1566402278",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_a099416b9f3e09d47c42f87667e6ad6f394ba652.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_a099416b9f3e09d47c42f87667e6ad6f394ba652.1920x1080.jpg?t=1566402278",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_e87e72a247918d8493892e035d5e1b4b84470d2f.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_e87e72a247918d8493892e035d5e1b4b84470d2f.1920x1080.jpg?t=1566402278",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_6f305b9603c17d31ddcbda4c73add319bf910a41.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_6f305b9603c17d31ddcbda4c73add319bf910a41.1920x1080.jpg?t=1566402278",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_2326cef0a0a81d2fdfe276f78c260222cb5e968c.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_2326cef0a0a81d2fdfe276f78c260222cb5e968c.1920x1080.jpg?t=1566402278",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_65dde6f056018945351e18f55c3481fa2478547b.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_65dde6f056018945351e18f55c3481fa2478547b.1920x1080.jpg?t=1566402278",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_ffef382d2a1ace63b82184caf3f6c5b293c66483.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_ffef382d2a1ace63b82184caf3f6c5b293c66483.1920x1080.jpg?t=1566402278",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_ac28000ade40cc2fe5c128f32ac98ba33c008a7a.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_ac28000ade40cc2fe5c128f32ac98ba33c008a7a.1920x1080.jpg?t=1566402278",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_b4d8d567f7bdcccc7195ca71d69f98a78575b96c.600x338.jpg?t=1566402278",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/588650/ss_b4d8d567f7bdcccc7195ca71d69f98a78575b96c.1920x1080.jpg?t=1566402278",
        },
      ],
    },
    {
      gameId: 5,
      steamid: 261570,
      gameName: "Ori and the Blind Forest",
      score: 96,
      ratings: 25133,
      gameHeader: "ori-and-the-blind-forest.jpg",
      developer: "Moon Studios",
      shortDescription: "“Ori and the Blind Forest” tells the tale of a young orphan destined for heroics, through a visually stunning action-platformer crafted by Moon Studios for PC. ",
      gameChip: ["Beautiful artstyle"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/387290/Ori_and_the_Blind_Forest_Definitive_Edition/",
      },
      reasonsToPlay: ["Atmospheric", "Emotional story", "Soundtrack", "Fun and challenging platforming", "Metroidvania"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_c617379b9d195eed0342f3ecf86898513e702b96.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_c617379b9d195eed0342f3ecf86898513e702b96.1920x1080.jpg?t=1557766332",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_a4dbe240c363fe04e39a13c9bd78c88c026f3c50.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_a4dbe240c363fe04e39a13c9bd78c88c026f3c50.1920x1080.jpg?t=1557766332",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_38552fb1001c03b5ccce2e3979270b2ecfd944c2.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_38552fb1001c03b5ccce2e3979270b2ecfd944c2.1920x1080.jpg?t=1557766332",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_85f66e8586a70b4fea5602a9d82ef2bf42c633b6.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_85f66e8586a70b4fea5602a9d82ef2bf42c633b6.1920x1080.jpg?t=1557766332",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_3b56520665b8fe3bba8df7e4cd239273c7156ab1.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_3b56520665b8fe3bba8df7e4cd239273c7156ab1.1920x1080.jpg?t=1557766332",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_9b1e2d00b8582547070a8aab136372f75ffb4024.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_9b1e2d00b8582547070a8aab136372f75ffb4024.1920x1080.jpg?t=1557766332",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_2cbce95c5a43044c75ef738b342601e58358a484.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_2cbce95c5a43044c75ef738b342601e58358a484.1920x1080.jpg?t=1557766332",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_3f2a983bcdf986d535017c674cc9c1c179a35e9d.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_3f2a983bcdf986d535017c674cc9c1c179a35e9d.1920x1080.jpg?t=1557766332",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_664ec024cc880884e66f9ce61fbbf64b3d73edf3.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_664ec024cc880884e66f9ce61fbbf64b3d73edf3.1920x1080.jpg?t=1557766332",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_0c7aaa2abf7394db16cb00fa336c2a07343ab01f.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_0c7aaa2abf7394db16cb00fa336c2a07343ab01f.1920x1080.jpg?t=1557766332",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_2b9906b55eea920d7faa610dc652b4766227e349.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_2b9906b55eea920d7faa610dc652b4766227e349.1920x1080.jpg?t=1557766332",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_873b3e4d8f96ea29c91aebe871af5e3915db3fe2.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_873b3e4d8f96ea29c91aebe871af5e3915db3fe2.1920x1080.jpg?t=1557766332",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_ffc15fac608d1e2c9f576bd81480e3d4a9f91e20.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_ffc15fac608d1e2c9f576bd81480e3d4a9f91e20.1920x1080.jpg?t=1557766332",
        },
        {
          id: 13,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_fa7b7c8cf8ab40f314c9a6c5fab382a7245daef9.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_fa7b7c8cf8ab40f314c9a6c5fab382a7245daef9.1920x1080.jpg?t=1557766332",
        },
        {
          id: 14,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_a0f942bffe1e33485c1b96c4e717c0bcc9797976.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_a0f942bffe1e33485c1b96c4e717c0bcc9797976.1920x1080.jpg?t=1557766332",
        },
        {
          id: 15,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_675a52a7a6c738acccc44e5b93e286e5c40fa0e1.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_675a52a7a6c738acccc44e5b93e286e5c40fa0e1.1920x1080.jpg?t=1557766332",
        },
        {
          id: 16,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_3ed9c721b5ee032f45bbc95d2c2ba0d8bd06269e.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_3ed9c721b5ee032f45bbc95d2c2ba0d8bd06269e.1920x1080.jpg?t=1557766332",
        },
        {
          id: 17,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_2d8a25f1cd27d054022ddc1ec7b37cdde55a96c8.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_2d8a25f1cd27d054022ddc1ec7b37cdde55a96c8.1920x1080.jpg?t=1557766332",
        },
        {
          id: 18,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_0063145dccd0cb165aa7d47d9ac6623445c7ad4c.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_0063145dccd0cb165aa7d47d9ac6623445c7ad4c.1920x1080.jpg?t=1557766332",
        },
        {
          id: 19,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_ed9fc79a76f8a55651e5bbd34b89457c263ac092.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_ed9fc79a76f8a55651e5bbd34b89457c263ac092.1920x1080.jpg?t=1557766332",
        },
        {
          id: 20,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_9d1c65e7ae503a48c55b002cb56cfdfedcd30bda.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_9d1c65e7ae503a48c55b002cb56cfdfedcd30bda.1920x1080.jpg?t=1557766332",
        },
        {
          id: 21,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_8c6bb342117fc21c2c2735bc1a94f118d96ae708.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_8c6bb342117fc21c2c2735bc1a94f118d96ae708.1920x1080.jpg?t=1557766332",
        },
        {
          id: 22,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_2d16ffc2ece133f898883714288e28bf0c99d91c.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_2d16ffc2ece133f898883714288e28bf0c99d91c.1920x1080.jpg?t=1557766332",
        },
        {
          id: 23,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_6848cfc295d16452eefc71520b4c824bff8f0fe5.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_6848cfc295d16452eefc71520b4c824bff8f0fe5.1920x1080.jpg?t=1557766332",
        },
        {
          id: 24,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_0ec31d07eb32469e3dfcef8f0ce58ecf75508726.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_0ec31d07eb32469e3dfcef8f0ce58ecf75508726.1920x1080.jpg?t=1557766332",
        },
        {
          id: 25,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_29c95535846e6575bbea155f835075433381e5bf.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_29c95535846e6575bbea155f835075433381e5bf.1920x1080.jpg?t=1557766332",
        },
        {
          id: 26,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_a8be2510c3209d2a948908c486ed12b70dd023f9.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_a8be2510c3209d2a948908c486ed12b70dd023f9.1920x1080.jpg?t=1557766332",
        },
        {
          id: 27,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_bcc055d95261e3c1450b031c02d92bbd32f0ecf6.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_bcc055d95261e3c1450b031c02d92bbd32f0ecf6.1920x1080.jpg?t=1557766332",
        },
        {
          id: 28,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_6661b437afe4b6f8acb4d6c4b46f18fdb315f357.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_6661b437afe4b6f8acb4d6c4b46f18fdb315f357.1920x1080.jpg?t=1557766332",
        },
        {
          id: 29,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_82c785e437e33b8a7126ece24a3fd02ed9269586.600x338.jpg?t=1557766332",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/261570/ss_82c785e437e33b8a7126ece24a3fd02ed9269586.1920x1080.jpg?t=1557766332",
        },
      ],
    },
    {
      gameId: 6,
      steamid: 40800,
      gameName: "Super Meat Boy",
      score: 94,
      ratings: 18491,
      gameHeader: "super-meat-boy.jpg",
      developer: "Team Meat",
      shortDescription: "The infamous, tough-as-nails platformer comes to Steam with a playable Head Crab character (Steam-exclusive)!",
      gameChip: ["Super Mario in a bizarre and cruel world"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/40800/Super_Meat_Boy/",
      },
      reasonsToPlay: ["Creative levels", "Epic boss fights", "Difficult", "Rescue Bandage Girl"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_2482dad154fa38c32195a5301891ec7d9cefa7da.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_2482dad154fa38c32195a5301891ec7d9cefa7da.1920x1080.jpg?t=1540402690",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_652092068cc0d5e75fe3f199ad1bbc395806c1f2.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_652092068cc0d5e75fe3f199ad1bbc395806c1f2.1920x1080.jpg?t=1540402690",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_71f114e451550394500613d8ec8082ae3eac44e5.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_71f114e451550394500613d8ec8082ae3eac44e5.1920x1080.jpg?t=1540402690",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_d158c2843c714bf2294376e03d9c4d6c7cd96397.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_d158c2843c714bf2294376e03d9c4d6c7cd96397.1920x1080.jpg?t=1540402690",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_8579adfaa58c863497e71df1b36331d37e51aeab.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_8579adfaa58c863497e71df1b36331d37e51aeab.1920x1080.jpg?t=1540402690",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_84f8832c8cfb5d93dba79c099b0067e22447cbab.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_84f8832c8cfb5d93dba79c099b0067e22447cbab.1920x1080.jpg?t=1540402690",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_1d69a008fd9716dd2c35f8c1b5ccd467cd1a2ad3.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_1d69a008fd9716dd2c35f8c1b5ccd467cd1a2ad3.1920x1080.jpg?t=1540402690",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_caa6db7059ac1e918ea38d643ef752e2dccef970.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_caa6db7059ac1e918ea38d643ef752e2dccef970.1920x1080.jpg?t=1540402690",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_dd29364268bcc989db0b56f3c2a95c67fc6f6761.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_dd29364268bcc989db0b56f3c2a95c67fc6f6761.1920x1080.jpg?t=1540402690",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_b2998adf37b0276c240315732169e06886942e78.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_b2998adf37b0276c240315732169e06886942e78.1920x1080.jpg?t=1540402690",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_06d79fbc925d6d8e6709b9878885dcce8e0b689a.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_06d79fbc925d6d8e6709b9878885dcce8e0b689a.1920x1080.jpg?t=1540402690",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_1e0f1c101a41a4120b552be8c4e037ef5025102a.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_1e0f1c101a41a4120b552be8c4e037ef5025102a.1920x1080.jpg?t=1540402690",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_b0e5343cb98e7811ed6db19311ce7fada7889442.600x338.jpg?t=1540402690",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/40800/ss_b0e5343cb98e7811ed6db19311ce7fada7889442.1920x1080.jpg?t=1540402690",
        },
      ],
    },
    {
      gameId: 4,
      steamid: 250760,
      gameName: "Shovel Knight: Treasure Trove",
      score: 96,
      ratings: 8468,
      gameHeader: "shovel-knight-treasure-trove.jpg",
      developer: "Yacht Club Games",
      shortDescription: "Shovel Knight: Treasure Trove is the full and complete edition of Shovel Knight, a sweeping classic action adventure game series with awesome gameplay, memorable characters, and an 8-bit retro aesthetic.",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/250760/Shovel_Knight_Treasure_Trove/",
        nintendo: "https://www.nintendo.de/Spiele/Nintendo-Switch-Download-Software/Shovel-Knight-Treasure-Trove-1200313.html",
      },
      reasonsToPlay: ["Content-heavy", "Classic 8-bit Retro look & soundtrack", "New game+", "Kill scary looking bosses with your shovel!", "Co-op"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_c10f7b4ff13255c54fd54a8df92b30d102df3c8f.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_c10f7b4ff13255c54fd54a8df92b30d102df3c8f.1920x1080.jpg?t=1568336220",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_7d6173c8b6b9d0ae8d4e30ddf05fc5663bb9f14b.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_7d6173c8b6b9d0ae8d4e30ddf05fc5663bb9f14b.1920x1080.jpg?t=1568336220",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_22d3531bdfb4f934388293e1bff6cf4272d823e2.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_22d3531bdfb4f934388293e1bff6cf4272d823e2.1920x1080.jpg?t=1568336220",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_1cdf8e35265a1a6951a6d35e2eb00b22d40cc128.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_1cdf8e35265a1a6951a6d35e2eb00b22d40cc128.1920x1080.jpg?t=1568336220",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_74b2fe7d2e709965079ddda196245ac24583765c.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_74b2fe7d2e709965079ddda196245ac24583765c.1920x1080.jpg?t=1568336220",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_fffb8f45bf767398e57efe0e1a5a1326d7f8bf5e.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_fffb8f45bf767398e57efe0e1a5a1326d7f8bf5e.1920x1080.jpg?t=1568336220",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_77b1cdeb564ee017030e7ff88f647db7ada26206.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_77b1cdeb564ee017030e7ff88f647db7ada26206.1920x1080.jpg?t=1568336220",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_f0d2dfe0a02a4750b4953b028ce043b4ee86c3a9.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_f0d2dfe0a02a4750b4953b028ce043b4ee86c3a9.1920x1080.jpg?t=1568336220",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_cf9a46ca4d7941eaa1ba5f19a058addc4df49202.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_cf9a46ca4d7941eaa1ba5f19a058addc4df49202.1920x1080.jpg?t=1568336220",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_1dc2ba98d6e8d2ca12544674cce6a62758958222.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_1dc2ba98d6e8d2ca12544674cce6a62758958222.1920x1080.jpg?t=1568336220",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_363597542ca9119238eb846c3c873f4ea493dcf9.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_363597542ca9119238eb846c3c873f4ea493dcf9.1920x1080.jpg?t=1568336220",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_0bb0425139b57c639371a46cbabaabc0378d7296.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_0bb0425139b57c639371a46cbabaabc0378d7296.1920x1080.jpg?t=1568336220",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_e4748de30e1698907931efbc68e7c17befaae91f.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_e4748de30e1698907931efbc68e7c17befaae91f.1920x1080.jpg?t=1568336220",
        },
        {
          id: 13,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_1518e6e1a4f9140ee975c53e5ce19aaebb2545fa.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_1518e6e1a4f9140ee975c53e5ce19aaebb2545fa.1920x1080.jpg?t=1568336220",
        },
        {
          id: 14,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_7d92d9214190654f2d1294f9f3e92e8971cf8695.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_7d92d9214190654f2d1294f9f3e92e8971cf8695.1920x1080.jpg?t=1568336220",
        },
        {
          id: 15,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_d7cfd96d904884f58f47d0a7c517ad8ff318a809.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_d7cfd96d904884f58f47d0a7c517ad8ff318a809.1920x1080.jpg?t=1568336220",
        },
        {
          id: 16,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_5fd0950aaee3df4d90827276dcd8565fc2d33a61.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_5fd0950aaee3df4d90827276dcd8565fc2d33a61.1920x1080.jpg?t=1568336220",
        },
        {
          id: 17,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_52a5e16ce1fc1a3941f985135bd01d8288795bbf.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_52a5e16ce1fc1a3941f985135bd01d8288795bbf.1920x1080.jpg?t=1568336220",
        },
        {
          id: 18,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_be27c91845b7d2138e68371a2d2d28897ff47766.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_be27c91845b7d2138e68371a2d2d28897ff47766.1920x1080.jpg?t=1568336220",
        },
        {
          id: 19,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_6ac866409e61896624a2566c872f055c2198b024.600x338.jpg?t=1568336220",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/250760/ss_6ac866409e61896624a2566c872f055c2198b024.1920x1080.jpg?t=1568336220",
        },
      ],
    },
    {
      gameId: 8,
      steamid: 65300,
      gameName: "Dustforce DX",
      score: 93,
      ratings: 904,
      gameHeader: "dustforce-dx.jpg",
      developer: "Hitbox Team",
      shortDescription: "Take control of a nimble janitor to rid the world of dust and debris. ",
      gameChip: ["Broom Parcour!"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/65300/Dustforce_DX/",
      },
      reasonsToPlay: ["High skill cap", "Movement is smooth and feels great", "Unique game idea", "Local multiplayer", "Janitor simulator"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_e0059e04b7e1eb8895874ad19c5aa8a5ef0e59bc.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_e0059e04b7e1eb8895874ad19c5aa8a5ef0e59bc.1920x1080.jpg?t=1529257110",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_93a737447b59e01622031d28512f87a247c1c6ad.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_93a737447b59e01622031d28512f87a247c1c6ad.1920x1080.jpg?t=1529257110",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_d381d01cffbb5e91a277160a50dd4e6e12449663.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_d381d01cffbb5e91a277160a50dd4e6e12449663.1920x1080.jpg?t=1529257110",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_1fced9444ea3ea7cf38d4f21e40088cf61b17d25.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_1fced9444ea3ea7cf38d4f21e40088cf61b17d25.1920x1080.jpg?t=1529257110",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_9e2831aaad80d7c00e29bc1b929d9d45b9d26658.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_9e2831aaad80d7c00e29bc1b929d9d45b9d26658.1920x1080.jpg?t=1529257110",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_603064b7161f4a7be91a9af0e25194b98e121e7a.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_603064b7161f4a7be91a9af0e25194b98e121e7a.1920x1080.jpg?t=1529257110",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_40542eba2a6520ec86a49decf58368c40b35497a.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_40542eba2a6520ec86a49decf58368c40b35497a.1920x1080.jpg?t=1529257110",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_dd9b0c29291b122e6bcb627f99363e2c58341a2f.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_dd9b0c29291b122e6bcb627f99363e2c58341a2f.1920x1080.jpg?t=1529257110",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_5032a6898f8d7fa5ff6a0fca971b259c6f4accd8.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_5032a6898f8d7fa5ff6a0fca971b259c6f4accd8.1920x1080.jpg?t=1529257110",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_0add9f7b85ec4255a10a4228d66fdc9773a663f9.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_0add9f7b85ec4255a10a4228d66fdc9773a663f9.1920x1080.jpg?t=1529257110",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_22bfea72f48e2090d5734e0cbb79317a801bf6eb.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_22bfea72f48e2090d5734e0cbb79317a801bf6eb.1920x1080.jpg?t=1529257110",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_e3be6037a8b73bace99ce54400302d6ecc0cc05b.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_e3be6037a8b73bace99ce54400302d6ecc0cc05b.1920x1080.jpg?t=1529257110",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_21a9323dbb87ad173edaf908076967ed2816bdf5.600x338.jpg?t=1529257110",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/65300/ss_21a9323dbb87ad173edaf908076967ed2816bdf5.1920x1080.jpg?t=1529257110",
        },
      ],
    },
    {
      gameId: 9,
      steamid: 241600,
      gameName: "Rogue Legacy",
      score: 93,
      ratings: 12779,
      gameHeader: "rogue-legacy.jpg",
      developer: "Cellar Door Games",
      shortDescription: "Rogue Legacy is a genealogical rogue-\"LITE\" where anyone can be a hero. Each time you die, your child will succeed you. Every child is unique. One child might be colorblind, another might have vertigo-- they could even be a dwarf. But that's OK, because no one is perfect, and you don't have to be to succeed.",
      gameChip: [],
      storeLinks: {
        steam: "https://store.steampowered.com/app/241600/Rogue_Legacy/",
      },
      reasonsToPlay: ["Procedurally generated levels", "A modern day Ghosts n Goblins", "Tons of secrets and easter eggs", "Rogue-lite"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_42804c992b120b548816f72ddbf4d4029df45063.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_42804c992b120b548816f72ddbf4d4029df45063.1920x1080.jpg?t=1568762569",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_50f6fb74c984beebd86bf33a988c6c3ca09bed84.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_50f6fb74c984beebd86bf33a988c6c3ca09bed84.1920x1080.jpg?t=1568762569",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_837c7b3ada2265a0f40d48138111a02a5ce4fb22.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_837c7b3ada2265a0f40d48138111a02a5ce4fb22.1920x1080.jpg?t=1568762569",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_1240a41810b577a678c7ef6f831eb385e178c5e4.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_1240a41810b577a678c7ef6f831eb385e178c5e4.1920x1080.jpg?t=1568762569",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_c9130e321806fd0100db3fdacccf75437da00bb1.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_c9130e321806fd0100db3fdacccf75437da00bb1.1920x1080.jpg?t=1568762569",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_9ebf5ab75856d9d6ea9cb82d1af668f1f953db77.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_9ebf5ab75856d9d6ea9cb82d1af668f1f953db77.1920x1080.jpg?t=1568762569",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_5680e84beea07396c7cc287c0a61de7273296874.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_5680e84beea07396c7cc287c0a61de7273296874.1920x1080.jpg?t=1568762569",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_83fd8ec5d72359d674c291b562144c4200bc644b.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_83fd8ec5d72359d674c291b562144c4200bc644b.1920x1080.jpg?t=1568762569",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_fb2ffc96c48196ed29c354b2889a5448d56fdf4f.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_fb2ffc96c48196ed29c354b2889a5448d56fdf4f.1920x1080.jpg?t=1568762569",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_4ae3c92dd271cb861f1e499702e635e58c691d84.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_4ae3c92dd271cb861f1e499702e635e58c691d84.1920x1080.jpg?t=1568762569",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_205e70a6375172ec92e1c62473a60b8af1844bce.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_205e70a6375172ec92e1c62473a60b8af1844bce.1920x1080.jpg?t=1568762569",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_6fe08a3479cd00c7aa08d69be21e83200f757435.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_6fe08a3479cd00c7aa08d69be21e83200f757435.1920x1080.jpg?t=1568762569",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_7c77cfccf48d6d416c45796c529cb31d0632c048.600x338.jpg?t=1568762569",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/241600/ss_7c77cfccf48d6d416c45796c529cb31d0632c048.1920x1080.jpg?t=1568762569",
        },
      ],
    },
    {
      gameId: 10,
      steamid: 764790,
      gameName: "The Messenger",
      score: 95,
      ratings: 2517,
      gameHeader: "the-messenger.jpg",
      developer: "Sabotage, Devolver Digital",
      shortDescription: "As a demon army besieges his village, a young ninja ventures through a cursed world, to deliver a scroll paramount to his clan’s survival. What begins as a classic action platformer soon unravels into an expansive time-traveling adventure full of thrills, surprises, and humor. ",
      gameChip: ["Ninja Adventure"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/764790/The_Messenger/",
      },
      reasonsToPlay: ["Authentic old-school 8-bit graphics", "Athmospheric diverse level design", "Awesome handmade chiptune soundtrack"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_8e0fa7f8226d8f8fd0dd313e26e6b4d97562f7a3.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_8e0fa7f8226d8f8fd0dd313e26e6b4d97562f7a3.1920x1080.jpg?t=1568596893",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_245d381f3b4a280818c73ef3769a92ea3a5a23b7.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_245d381f3b4a280818c73ef3769a92ea3a5a23b7.1920x1080.jpg?t=1568596893",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_105fd3de6a4dbf2cb848f2c265212ae3eb7434e5.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_105fd3de6a4dbf2cb848f2c265212ae3eb7434e5.1920x1080.jpg?t=1568596893",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_8dd705de7ce4ae51d6f6c70fa577fbb3232c0ce0.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_8dd705de7ce4ae51d6f6c70fa577fbb3232c0ce0.1920x1080.jpg?t=1568596893",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_480583aad07967ee7792e15528d1493280e904b2.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_480583aad07967ee7792e15528d1493280e904b2.1920x1080.jpg?t=1568596893",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_9b2577846d890f6fd008bfe465322126b5ce1c54.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_9b2577846d890f6fd008bfe465322126b5ce1c54.1920x1080.jpg?t=1568596893",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_875fe259c26f29d959efba0eca47e1b63d844c75.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_875fe259c26f29d959efba0eca47e1b63d844c75.1920x1080.jpg?t=1568596893",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_1e3c0e79c6f85bd6700c617a219169851cee9619.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_1e3c0e79c6f85bd6700c617a219169851cee9619.1920x1080.jpg?t=1568596893",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_98dce81187ce61b11293879df6eadb6fa8207fb3.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_98dce81187ce61b11293879df6eadb6fa8207fb3.1920x1080.jpg?t=1568596893",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_5954b762b12052fb57cd8efae74c9ab2864c96fb.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_5954b762b12052fb57cd8efae74c9ab2864c96fb.1920x1080.jpg?t=1568596893",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_a2411df6edd809eaf708b55af0fbe13740199133.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_a2411df6edd809eaf708b55af0fbe13740199133.1920x1080.jpg?t=1568596893",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_622d15d89486e1232b98e0e63f20e631330ad312.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_622d15d89486e1232b98e0e63f20e631330ad312.1920x1080.jpg?t=1568596893",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_3b8ee4c743162a1f19966b0d785457cd3fd967a5.600x338.jpg?t=1568596893",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/764790/ss_3b8ee4c743162a1f19966b0d785457cd3fd967a5.1920x1080.jpg?t=1568596893",
        },
      ],
    },
    {
      gameId: 4,
      steamid: 367520,
      gameName: "Hollow Knight",
      score: 95,
      ratings: 45003,
      gameHeader: "hollow-knight.jpg",
      developer: "Team Cherry",
      shortDescription: "Forge your own path in Hollow Knight! An epic action adventure through a vast ruined kingdom of insects and heroes. Explore twisting caverns, battle tainted creatures and befriend bizarre bugs, all in a classic, hand-drawn 2D style. ",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/367520/Hollow_Knight/",
      },
      reasonsToPlay: ["Atmospheric", "Story heavy", "Difficult", "30 epic bossfights", "Metroidvania", "Rewarding and challenging"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_5384f9f8b96a0b9934b2bc35a4058376211636d2.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_5384f9f8b96a0b9934b2bc35a4058376211636d2.1920x1080.jpg?t=1568794924",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_d5b6edd94e77ba6db31c44d8a3c09d807ab27751.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_d5b6edd94e77ba6db31c44d8a3c09d807ab27751.1920x1080.jpg?t=1568794924",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_a81e4231cc8d55f58b51a4a938898af46503cae5.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_a81e4231cc8d55f58b51a4a938898af46503cae5.1920x1080.jpg?t=1568794924",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_62e10cf506d461e11e050457b08aa0e2a1c078d0.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_62e10cf506d461e11e050457b08aa0e2a1c078d0.1920x1080.jpg?t=1568794924",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_bd76bd88bc5334ee56ae3d5f0d8dec4455e8e3b8.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_bd76bd88bc5334ee56ae3d5f0d8dec4455e8e3b8.1920x1080.jpg?t=1568794924",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_33a645903d6dd9beec39f272a3daf57174a6cc26.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_33a645903d6dd9beec39f272a3daf57174a6cc26.1920x1080.jpg?t=1568794924",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_47f3523dbea462aff2ca4bc9f605faaf80a792b2.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_47f3523dbea462aff2ca4bc9f605faaf80a792b2.1920x1080.jpg?t=1568794924",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_92c7e8f34c00bdb455070ecdd5b746f0d2f6d808.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_92c7e8f34c00bdb455070ecdd5b746f0d2f6d808.1920x1080.jpg?t=1568794924",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_946e83ea924eff46a93e14e841bdbb1accd1654c.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_946e83ea924eff46a93e14e841bdbb1accd1654c.1920x1080.jpg?t=1568794924",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_b17396ebcbef87cd016c433927bb2dd7edb17660.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_b17396ebcbef87cd016c433927bb2dd7edb17660.1920x1080.jpg?t=1568794924",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_f1f594e1057a124d289a5905b53b5a0f820982c0.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_f1f594e1057a124d289a5905b53b5a0f820982c0.1920x1080.jpg?t=1568794924",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_2329fe632e788efd9ece9dcfc98c0ea723ac0d04.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_2329fe632e788efd9ece9dcfc98c0ea723ac0d04.1920x1080.jpg?t=1568794924",
        },
        {
          id: 12,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_fbe692d154a3d9dd627966d5445c4647ae0f71ce.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_fbe692d154a3d9dd627966d5445c4647ae0f71ce.1920x1080.jpg?t=1568794924",
        },
        {
          id: 13,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_1552562bd322fb94fd8c5a6595e75f1e6808db41.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_1552562bd322fb94fd8c5a6595e75f1e6808db41.1920x1080.jpg?t=1568794924",
        },
        {
          id: 14,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_5478c9192023a3b3e438c030caa0809b62523587.600x338.jpg?t=1568794924",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/367520/ss_5478c9192023a3b3e438c030caa0809b62523587.1920x1080.jpg?t=1568794924",
        },
      ],
    },
    {
      gameId: 7,
      steamid: 268910,
      gameName: "Cuphead",
      score: 95,
      ratings: 37659,
      gameHeader: "cuphead.jpg",
      developer: "Studio MDHR",
      shortDescription: "Cuphead is a classic run and gun action game heavily focused on boss battles. Inspired by cartoons of the 1930s, the visuals and audio are painstakingly created with the same techniques of the era, i.e. traditional hand drawn cel animation, watercolor backgrounds, and original jazz recordings. ",
      gameChip: ["Unique Cartoon Graphics"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/268910/Cuphead/",
      },
      reasonsToPlay: ["Bossfights", "Difficult", "2D", "Incredible art style", "Local co-op"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_615455299355eaf552c638c7ea5b24a8b46e02dd.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_615455299355eaf552c638c7ea5b24a8b46e02dd.1920x1080.jpg?t=1555602930",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_483fb089be0093beeef03525276803a9ca4f66a1.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_483fb089be0093beeef03525276803a9ca4f66a1.1920x1080.jpg?t=1555602930",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_48477e4a865827aa0be6a44f00944d8d2a3e5eb9.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_48477e4a865827aa0be6a44f00944d8d2a3e5eb9.1920x1080.jpg?t=1555602930",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_380296effbf1073bbedfd480e50cf246eb542b66.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_380296effbf1073bbedfd480e50cf246eb542b66.1920x1080.jpg?t=1555602930",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_aefad3850c3bc04000cbe0d620bea6807e0a0331.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_aefad3850c3bc04000cbe0d620bea6807e0a0331.1920x1080.jpg?t=1555602930",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_1bfaa0d236aa99cdc31234aa7aad314898e3d10e.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_1bfaa0d236aa99cdc31234aa7aad314898e3d10e.1920x1080.jpg?t=1555602930",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_874f2d27a9120ee60cbce0c7bd4085525fd09b26.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_874f2d27a9120ee60cbce0c7bd4085525fd09b26.1920x1080.jpg?t=1555602930",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_ae3db08c403209d868e52ae513540e1ba0489302.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_ae3db08c403209d868e52ae513540e1ba0489302.1920x1080.jpg?t=1555602930",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_1335c63f0429da926e8cfd0e1faf59ff65d2f6ef.600x338.jpg?t=1555602930",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/268910/ss_1335c63f0429da926e8cfd0e1faf59ff65d2f6ef.1920x1080.jpg?t=1555602930",
        },
      ],
    },
    {
      gameId: 11,
      steamid: 318530,
      gameName: "Wings of Vi",
      score: 85,
      ratings: 846,
      gameHeader: "wings-of-vi.jpg",
      developer: "Grynsoft",
      shortDescription: "Wings of Vi is a challenging 2D platformer in which the player controls the angel Vi, tasked with defeating the Demon Lord Jeh’oul after he is freed from captivity. While inspired by masochistic retro platformers, Wings of Vi aims to cut out arbitrary difficulty in favor of skill based gameplay. ",
      gameChip: [],
      storeLinks: {
        steam: "https://store.steampowered.com/app/318530/Wings_of_Vi/",
      },
      reasonsToPlay: ["Challenging gameplay", "Inspired by Megaman and Castlevania", "Intense boss fights", "Super tight 2D action platformer"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_8a0932d1a0f39a69edb9aaad986f6ad58c640159.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_8a0932d1a0f39a69edb9aaad986f6ad58c640159.1920x1080.jpg?t=1447363183",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_ee18b6b37f33aebd81a77d660775339754657b7f.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_ee18b6b37f33aebd81a77d660775339754657b7f.1920x1080.jpg?t=1447363183",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_a424353ddacce2bc7145a74348f62c6d42193266.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_a424353ddacce2bc7145a74348f62c6d42193266.1920x1080.jpg?t=1447363183",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_8e7a02d4d63e26913178ebdf7fd6832ae7100dd0.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_8e7a02d4d63e26913178ebdf7fd6832ae7100dd0.1920x1080.jpg?t=1447363183",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_56285980f4481427348ce8a0492d9391cb9fbac1.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_56285980f4481427348ce8a0492d9391cb9fbac1.1920x1080.jpg?t=1447363183",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_d1975b25924e73b60dff3b1ff3373bb3366829d9.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_d1975b25924e73b60dff3b1ff3373bb3366829d9.1920x1080.jpg?t=1447363183",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_5b376fb7330277e32f4eb739dfcd89e03fe5af5c.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_5b376fb7330277e32f4eb739dfcd89e03fe5af5c.1920x1080.jpg?t=1447363183",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_399ea9d82c0699a1049df7089a8bf02c6c97db06.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_399ea9d82c0699a1049df7089a8bf02c6c97db06.1920x1080.jpg?t=1447363183",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_2ba1b7b4c378f559c16504053b0e9f215dc9e5ab.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_2ba1b7b4c378f559c16504053b0e9f215dc9e5ab.1920x1080.jpg?t=1447363183",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_b0092c51a3f1cccd55a831fc9eed0d5ff1cdec54.600x338.jpg?t=1447363183",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/318530/ss_b0092c51a3f1cccd55a831fc9eed0d5ff1cdec54.1920x1080.jpg?t=1447363183",
        },
      ],
    },

    {
      gameId: 11,
      steamid: 230270,
      gameName: "N++ (NPLUSPLUS)",
      score: 96,
      ratings: 1401,
      gameHeader: "n-plus-plus.jpg",
      developer: "Metanet Software Inc.",
      shortDescription: "N++ is a fast-paced, momentum-based platformer about darting around obstacles, narrowly evading enemies and collecting gold in a beautiful minimalist landscape.",
      gameChip: [],
      storeLinks: {
        steam: "https://store.steampowered.com/app/230270/N_NPLUSPLUS/",
      },
      reasonsToPlay: ["Simple but hard to master gameplay", "Over 4300 hand-made levels", "Local Co-op"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_9a1b37fe4a4b192bfb9f42a83cf37ba24a3758a2.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_9a1b37fe4a4b192bfb9f42a83cf37ba24a3758a2.1920x1080.jpg?t=1527784575",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_262b6403f0e955f1f4a6c80c1914e0e997d06e9a.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_262b6403f0e955f1f4a6c80c1914e0e997d06e9a.1920x1080.jpg?t=1527784575",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_717a25cb3bf6709000c9d1ad0e6c17e5d81d9181.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_717a25cb3bf6709000c9d1ad0e6c17e5d81d9181.1920x1080.jpg?t=1527784575",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_a81b899ec6fb836e37089fea1f07e179d18ee6cf.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_a81b899ec6fb836e37089fea1f07e179d18ee6cf.1920x1080.jpg?t=1527784575",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_9914aac1667a85b72a07e6e0a63e249c4a571d67.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_9914aac1667a85b72a07e6e0a63e249c4a571d67.1920x1080.jpg?t=1527784575",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_e20d25f4d1b83220e06ed48a9c25ed83f03a0769.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_e20d25f4d1b83220e06ed48a9c25ed83f03a0769.1920x1080.jpg?t=1527784575",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_8c5e94903af10723723262c9e699e4c27fd3cafd.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_8c5e94903af10723723262c9e699e4c27fd3cafd.1920x1080.jpg?t=1527784575",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_99fbae10614c0b6bc171cf849a08efcb2f2d35d4.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_99fbae10614c0b6bc171cf849a08efcb2f2d35d4.1920x1080.jpg?t=1527784575",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_07e618891a72b5325d7610cf8e4521065234dc92.600x338.jpg?t=1527784575",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/230270/ss_07e618891a72b5325d7610cf8e4521065234dc92.1920x1080.jpg?t=1527784575",
        },
      ],
    },

    {
      gameId: 11,
      steamid: 360740,
      gameName: "Downwell",
      score: 96,
      ratings: 4572,
      gameHeader: "downwell.jpg",
      developer: "Moppin",
      shortDescription: "Downwell is a curious game about a young person venturing down a well in search of untold treasures with only his Gunboots to protect him.",
      gameChip: [],
      storeLinks: {
        steam: "https://store.steampowered.com/app/360740/Downwell/",
      },
      reasonsToPlay: ["Looks like it has been released 1980", "Blow stuff up)", "Unique vertical level design", "Procedurally generated levels", "Unique Weapons and Items"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_96fa27e853e9513de825df4264e356066a3c5145.600x338.jpg?t=1568665075",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_96fa27e853e9513de825df4264e356066a3c5145.1920x1080.jpg?t=1568665075",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_eaab64c0e734e568912fa3ff435e7bd09a28f70b.600x338.jpg?t=1568665075",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_eaab64c0e734e568912fa3ff435e7bd09a28f70b.1920x1080.jpg?t=1568665075",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_c4f5edef3fb078d2c5483f8394cc5d500eb2e96c.600x338.jpg?t=1568665075",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_c4f5edef3fb078d2c5483f8394cc5d500eb2e96c.1920x1080.jpg?t=1568665075",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_9e95c27552152d430797fa2a4c873e3ed8cab2ea.600x338.jpg?t=1568665075",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_9e95c27552152d430797fa2a4c873e3ed8cab2ea.1920x1080.jpg?t=1568665075",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_ec8b0acf4fadae7315cfba1a85b0f04832d16576.600x338.jpg?t=1568665075",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/360740/ss_ec8b0acf4fadae7315cfba1a85b0f04832d16576.1920x1080.jpg?t=1568665075",
        },
      ],
    },
    {
      gameId: 11,
      steamid: 384280,
      gameName: "Mute Crimson+",
      score: 85,
      ratings: 106,
      gameHeader: "mute-crimson-plus.jpg",
      developer: "Iced Lizard Games",
      shortDescription: "Be a ninja and go on an action platforming adventure to save the world. Climb walls, slice up bad guys, master tricky platforming sequences and face ruthless bosses.",
      gameChip: [],
      storeLinks: {
        steam: "https://store.steampowered.com/app/384280/Mute_Crimson/",
      },
      reasonsToPlay: ["Intense boss battles", "Challenging!", "Intelligent and satisfying level design"],
      awards: [],
      media: [
        {
          id: 0,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_dde9cd842f5d12d47d2a9729468d2cc91de63c72.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_dde9cd842f5d12d47d2a9729468d2cc91de63c72.1920x1080.jpg?t=1561498016",
        },
        {
          id: 1,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_2e4297e0fbec41155b107130bbd8ffa7048fd552.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_2e4297e0fbec41155b107130bbd8ffa7048fd552.1920x1080.jpg?t=1561498016",
        },
        {
          id: 2,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_9a0aa6ec90e4b6643774ca59856372a9d09e71ce.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_9a0aa6ec90e4b6643774ca59856372a9d09e71ce.1920x1080.jpg?t=1561498016",
        },
        {
          id: 3,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_f5be1d5b47a39547adf07096f9f20a0215444522.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_f5be1d5b47a39547adf07096f9f20a0215444522.1920x1080.jpg?t=1561498016",
        },
        {
          id: 4,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_217d3ed772dc81e216a8f49df545ce766aada290.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_217d3ed772dc81e216a8f49df545ce766aada290.1920x1080.jpg?t=1561498016",
        },
        {
          id: 5,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_61f53d5ccb53a774f0d9896e2b13b40025871c6f.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_61f53d5ccb53a774f0d9896e2b13b40025871c6f.1920x1080.jpg?t=1561498016",
        },
        {
          id: 6,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_1dd68d2e082eaaa8c41bb3a4f7866f4240972972.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_1dd68d2e082eaaa8c41bb3a4f7866f4240972972.1920x1080.jpg?t=1561498016",
        },
        {
          id: 7,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_ee8bf02dbc20041bcd047c98dde47f529d4f108e.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_ee8bf02dbc20041bcd047c98dde47f529d4f108e.1920x1080.jpg?t=1561498016",
        },
        {
          id: 8,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_5da45851fbd4d72b52a23fa52239bb42da012730.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_5da45851fbd4d72b52a23fa52239bb42da012730.1920x1080.jpg?t=1561498016",
        },
        {
          id: 9,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_b79282a14dfcc44826840df52b01d5c097a65799.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_b79282a14dfcc44826840df52b01d5c097a65799.1920x1080.jpg?t=1561498016",
        },
        {
          id: 10,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_fe64474f659100a9a4e2fb6d659cbc5184556636.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_fe64474f659100a9a4e2fb6d659cbc5184556636.1920x1080.jpg?t=1561498016",
        },
        {
          id: 11,
          thumbnail: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_e5a3e691f8d60403d8ba3f4f34b9e5f39883190b.600x338.jpg?t=1561498016",
          original: "https://steamcdn-a.akamaihd.net/steam/apps/384280/ss_e5a3e691f8d60403d8ba3f4f34b9e5f39883190b.1920x1080.jpg?t=1561498016",
        },
      ],
    },

  ],
  stats: {
    number: [17, 17, 1],
    category: ["indie games", "community favorites", "tour"],
    reason: ["because Indies are boss", "", "for you to explore"],
  },
};
import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import Layout from "../../element/layout/Layout";
import { InnerColumnContainer } from "../../element/layout/LayoutSetBackground";
import { ooContentWidth } from "../../element/theme";
import LayoutColumn from "../../element/layout/LayoutColumn";
import OoSpacing from "../../element/page-element/OoSpacing";
import SEO from "../../element/SEO";
import TourUserAvatar from "../TourUserAvatar";
import Stats from "../Stats";
import starsRight
  from "../visual/background-discovery-tour-stars-right-OberionIO.min.svg";
import starsLeft
  from "../visual/background-discovery-tour-stars-left-OberionIO.min.svg";
import waveBottom
  from "../visual/background-discovery-tour-wave-bottom-OberionIO.min.svg";
import waveMiddle
  from "../visual/background-discovery-tour-wave-middle-va1-OberionIO.min.svg";
import waveTop from "../visual/background-discovery-tour-wave-top-OberionIO.min.svg";
import avatar from "../../library/visual/try-random-robot-OberionIO.min.svg";
import GamePreviewCeleste from "../game-preview/GamePreview-Celeste";
import FeedbackWidget from "./FeedbackWidget";
import UserFavorites from "../UserFavorites";

const seo = {
  title: "Similar Games to Celeste - Oberion",
  description: "Discover 12 games similar to indie gem 'Celeste'",
};
const componentSpacing = 5;
const heading = [
  "17 Games similar to Celeste",
  "Celeste was the most popular indie game in r/gamingsuggestions",
  "Discover more games similar to Celeste!",
  "Indie Gems",
  "From the same Developer",
  "Niche Favorites",

];

const BackgroundDiv = styled(InnerColumnContainer)`
  background-color: #3c00be;
  background-image: url(${starsRight}), url(${starsLeft}), url(${waveTop}), url(${waveMiddle}), url(${waveBottom});
  background-size: auto, auto, 100%, 100%, 100%;
  background-position: top right, top left, top center, center center, bottom center;
  background-repeat: repeat-y, repeat-y, no-repeat, no-repeat, no-repeat;
  
  align-items: center;
  justify-content: center;
`;
const ColumnDiv = styled.div`   
  display: flex; 
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  max-width: 50rem;
`;

const SimilarTo = ({ data, favorites }) => {
  let userFavorites = null;
  if (favorites != null) {
    userFavorites = (<UserFavorites data={favorites} />);
  }

  return (
    <Layout hideNewsletter>
      <SEO {...seo} />
      <BackgroundDiv>
        <LayoutColumn alignItems="center"
                      justifyContent="center"
                      textAlign="left"
                      maxWidth={ooContentWidth}
                      paddingBottom="5rem"
                      paddingTop="5rem">

          <Typography variant="h1" align="center" gutterBottom>{heading[0]}</Typography>
          <FeedbackWidget />

          <ColumnDiv>
            <TourUserAvatar src={avatar} />
            <Typography variant="h5" gutterBottom>{heading[1]}</Typography>
          </ColumnDiv>
          {userFavorites}
          <GamePreviewCeleste game={data.games[0]} />

          <OoSpacing marginTop={componentSpacing} />
          <Typography variant="h3" align="center" paragraph>{heading[2]}</Typography>
          <Stats stats={data.stats} />

          {data.games.map((element) => {
            if (element.gameId === 0) return null;
            return (<GamePreviewCeleste key={element.gameName} game={element} />);
          })}

        </LayoutColumn>
      </BackgroundDiv>
    </Layout>
  );
};
SimilarTo.defaultProps = {
  favorites: null,
};
SimilarTo.propTypes = {
  data: PropTypes.shape({
    games: PropTypes.array.isRequired,
    stats: PropTypes.object.isRequired,
  }).isRequired,
  favorites: PropTypes.object,
};
export default SimilarTo;
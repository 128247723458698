import React from "react";
import { Button, Input, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import * as PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { ROUTES } from "../../navigation/InternalRoute";


/**
 * Better checkboxes
 */

const useStyles = makeStyles(theme => ({

  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(4, 0, 3),
  },
  sendIcon: {
    marginLeft: "0.4rem",
  },
  spacedButtonsDiv: {
    display: "flex",
    flexFlow: "row-reverse nowrap",
    justifyContent: "space-between",
  },
  conciseLegalNotice: {
    color: theme.palette.text.disabled,
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  group: {
    marginBottom: theme.spacing(2),
  },
  radioHidden: {
    display: "none",
  },
  radioTop: {
    marginTop: "-0.4rem",
    paddingLeft: "0.2rem",
  },
  radio: {
    marginTop: "-0.6rem",
    paddingLeft: "0.2rem",
  },
}));

const introText = [
  "Is this list helpful to you?",
  "Rate your experience",
  "We want to help players discover new games. Your anonymous feedback helps us improve.",
  "Thanks for checking out our project! 🔥",
];
const feedbackText = [
  "Anything else you would like to tell us?",
  "Game selection, design, favorite parcour move, ...",
  "Have you found interesting games that you haven't heard about yet?",
  "Are you planning to get any of those games?",
];

const FeedbackForm = ({ handleClose, showCancel }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>

      <Typography variant="h2" gutterBottom>Feedback</Typography>
      <Typography variant="caption" className={classes.conciseLegalNotice}>
        {introText[2]}
      </Typography>
      <Typography variant="caption" className={classes.conciseLegalNotice}>
        {introText[3]}
      </Typography>

      <form className={classes.form}
            name="celeste-feedback"
            method="POST"
            // enable netlify forms
            // data-netlify="true"
            // netlify-honeypot="bot-field"
            action={ROUTES.THANKS.TO}>

        <p className={classes.hidden}>
          {/* eslint-disable-next-line */}
          <label>The only winning move is not to play<input name="bot-field" /></label>
        </p>
        <input name="form-name" value="celeste-feedback" type="hidden" />

        <FormControl component="fieldset" className={classes.formControl}>
          <Typography variant="body1">{feedbackText[2]}</Typography>
          <RadioGroup
            name="newGames"
            className={classes.group}
          >
            <FormControlLabel
              value="newGames-default"
              label="default"
              htmlFor="newGamesDefault"
              control={(
                <Radio color="secondary"
                       type="radio" id="newGamesDefault" name="newGames" value="newGames-default" />
              )}
              labelPlacement="end"
              className={classes.radioHidden}
            />
            <FormControlLabel
              value="newGames-yes"
              label="Yes"
              htmlFor="newGamesYes"
              control={(
                <Radio color="secondary"
                       type="radio" id="newGamesYes" name="newGames" value="newGames-yes" />
              )}
              labelPlacement="end"
              className={classes.radioTop}
            />
            <FormControlLabel
              value="newGames-no"
              label="No"
              htmlFor="newGamesNo"
              control={(
                <Radio color="secondary"
                       type="radio" id="newGamesNo" name="newGames" value="newGames-no" />
              )}
              labelPlacement="end"
              className={classes.radio}
            />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <Typography variant="body1">{feedbackText[3]}</Typography>
          <RadioGroup
            name="planningToGet"
            className={classes.group}
          >
            <FormControlLabel
              value="planningToGet-default"
              label="default"
              htmlFor="planningToGetDefault"
              control={(
                <Radio color="secondary"
                       type="radio" id="planningToGetDefault"
                       name="planningToGet" value="planningToGet-default" />
              )}
              labelPlacement="end"
              className={classes.radioHidden}
            />
            <FormControlLabel
              value="planningToGet-yes"
              label="Yes"
              htmlFor="planningToGetYes"
              control={(
                <Radio color="secondary"
                       type="radio" id="planningToGetYes"
                       name="planningToGet" value="planningToGet-yes" />
              )}
              labelPlacement="end"
              className={classes.radioTop}
            />
            <FormControlLabel
              value="planningToGet-wishlist"
              label="Yes, I put some on my wishlist"
              htmlFor="planningToGetYesWishlist"
              control={(
                <Radio color="secondary"
                       type="radio" id="planningToGetYesWishlist"
                       name="planningToGet" value="planningToGet-wishlist" />
              )}
              labelPlacement="end"
              className={classes.radioTop}
            />
            <FormControlLabel
              value="planningToGet-bought"
              label="Yes, already bought some"
              htmlFor="planningToGetYesBought"
              control={(
                <Radio color="secondary"
                       type="radio" id="planningToGetYesBought"
                       name="planningToGet" value="planningToGet-bought" />
              )}
              labelPlacement="end"
              className={classes.radioTop}
            />
            <FormControlLabel
              value="planningToGet-no"
              label="No"
              htmlFor="planningToGetNo"
              control={(
                <Radio color="secondary"
                       type="radio" id="planningToGetNo"
                       name="planningToGet" value="planningToGet-no" />
              )}
              labelPlacement="end"
              className={classes.radio}
            />
          </RadioGroup>
        </FormControl>


        <Typography variant="body1" gutterBottom>{feedbackText[0]}</Typography>
        <Input
          type="text"
          name="generalFeedback"
          placeholder={feedbackText[1]}
          multiline
          fullWidth
          inputProps={{
            maxLength: 1000,
          }}
        />

        <div className={classes.spacedButtonsDiv}>
          <Button variant="contained"
                  color="secondary"
                  className={classes.submit}
                  type="submit" value="Submit"
          >
            Send Feedback
            <SendIcon className={classes.sendIcon} />
          </Button>
          {showCancel && (
            <Button variant="outlined"
                    color="secondary"
                    className={classes.submit}
                    onClick={handleClose}
            >
              Cancel
            </Button>
          )}

        </div>
      </form>
    </div>
  );
};
FeedbackForm.defaultProps = {
  showCancel: false,
};
FeedbackForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  showCancel: PropTypes.bool,
};
export default FeedbackForm;
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Modal, Chip } from "@material-ui/core";
import heartSvg from "../visual/heart-OberionIO.min.svg";
import FeedbackForm from "./FeedbackForm";
import { grey5 } from "../../element/theme";

const useStyles = makeStyles(theme => ({
  container: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    maxWidth: "28rem",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(6),
    outline: `solid ${grey5}`,
  },
  extended: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
    textTransform: "none",
    padding: theme.spacing(1),
  },
  notExtended: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
    textTransform: "none",
  },
  icon: {
    marginRight: "-0.6rem",
    height: "2.5rem",
  },
}));


const FeedbackWidget = () => {
  const classes = useStyles();
  const [isExtended, setExtended] = useState(true);
  const [isOpen, setOpen] = React.useState(false);
  const HeartIcon = () => (<img src={heartSvg} alt="Heart" className={classes.icon} />);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleDelete() {
    setExtended(false);
    provideChip(); // eslint-disable-line no-use-before-define
  }

  const provideChip = () => {
    if (isExtended) {
      return (
        <Chip
          icon={<HeartIcon />}
          label="Please provide feedback so we can make this site better"
          onClick={handleOpen}
          onDelete={handleDelete}
          className={classes.extended}
          color="secondary"
        />
      );
    }
    return (
      <Chip
        label="Feedback"
        onClick={handleOpen}
        className={classes.notExtended}
        color="secondary"
        variant="outlined"
      />
    );
  };

  return (

    <div>
      {provideChip(isExtended)}
      <Modal
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={handleClose}
      >
        <Container component="main" maxWidth="xs" className={classes.container}>
          <FeedbackForm handleClose={handleClose} showCancel />
        </Container>
      </Modal>
    </div>

  );
};
export default FeedbackWidget;
import React from "react";
import * as PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import styled from "styled-components";
import { lessOrEqualThan } from "../../../util/mediaQuery";

const SizedImageGallery = styled.div`
  max-width: 33rem;
  ${lessOrEqualThan.lg`max-width: 30rem;`};
  ${lessOrEqualThan.md`max-width: 90vw;`};
`;


/*
const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
];
 */

/**
 * @see https://github.com/xiaolin/react-image-gallery
 * @see video in image gallery: @see https://github.com/xiaolin/react-image-gallery/issues/436
 * Alternative for introducing users: @see https://reactjsexample.com/introduce-new-users-to-your-app-with-this-material-style-carousel/
 */
const MediaCarousel = ({ media }) => {
  return (
    <SizedImageGallery>
      <ImageGallery items={media} slideOnThumbnailOver showPlayButton={false} />
    </SizedImageGallery>
  );
};

MediaCarousel.propTypes = {
  media: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default MediaCarousel;